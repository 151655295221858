.enlargeImage-header-extend{
    background:#282828!important;
    /* text-align: justify !important;
    text-justify: inter-word !important; */
}

.enlargeImage-h1-extend{
    color:#E0D7C6!important;
    font-size: 43px !important;
    font-weight: bold;
}

.enlargeImage-h2-extend{
    color:#C3C3C3!important;
    text-align: justify !important;
    text-justify: inter-word !important;
}

.enlargeImage-navLink-extend{
    color:#E0D7C6 !important;
}
